import * as React from 'react';

import {useParams, useNavigate, useRevalidator} from 'react-router-dom';
import {useMessage} from "../context/MessageProvider";

import { useTheme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import {InfoOutlined} from '@mui/icons-material';
import EwpButton from "../components/EwpButton";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { getAssessment, saveAssessment} from '../models/assessments';
import { getElephant } from '../models/elephants';
import { listSectionBsByAssessment, completeSectionB } from '../models/sectionb';
import { listSectionAsByAssessment, completeSectionA } from '../models/sectiona';
import { listSectionCsByAssessment, completeSectionC } from '../models/sectionc';
import { listSectionCIoAsByAssessment, completeSectionCIoA } from '../models/sectioncioa';
import { listChanges } from "../models/changes";

import { isInProgress } from "../utils/assessments";
import { observationStatus, overallSectionStatus, inProgressObservation, finalEvaluationStatus } from '../utils/sections';
import { dateLocaleFormat } from "../utils/datetime";

import Subheader from '../components/Subheader';
import {useLoaderData} from 'react-router-dom';

import {datetime} from "../utils/datetime";

import { useConfirmationDialog } from '../context/ConfirmationDialogContext';
import { useConnectivity } from '../context/ConnectivityProvider';
import { useLayout } from "../components/layout/Layout";
import CustomTooltip from '../components/tooltip/CustomTooltip';

import { allOpenElephantChanges, sortDescStart } from '../utils/changes';

import ChangeSummaryCard from '../components/cards/ChangeSummaryCard';

import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useSectionB } from '../context/SectionBProvider';

import { createSignedUrl } from '../utils/utils';

import { getFinalEvaluation } from '../models/finalevaluation';

const SectionBObservation = ({record,addHandler,...props}) => {

    const status = observationStatus(record);

    return (
        <Stack sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
            <Typography>{record.observation_range} ({status})</Typography>
            {(status === "In Progress") ? <CustomTooltip keyString="add_to_clipboard" className=""><IconButton onClick={addHandler}><PlaylistAddIcon /></IconButton></CustomTooltip> : <></>}
        </Stack>
    );


};

export async function loader({params}) {
    console.log("loader params",params);

    const assessment = await getAssessment(params.assessmentId);
    const elephant = await getElephant(assessment.elephant_id);

    const sectionAs = await listSectionAsByAssessment(assessment.id);
    const sectionBs = await listSectionBsByAssessment(assessment.id);
    const sectionCs = await listSectionCsByAssessment(assessment.id);
    const sectionCIoAs = await listSectionCIoAsByAssessment(assessment.id);

    const finalevaluation = await getFinalEvaluation(params.assessmentId);

    const changes = await listChanges();


    const reportUrl = (assessment?.report_path) ? await createSignedUrl({bucketName: process.env.REACT_APP_ASSESSMENT_REPORTS_BUCKET,bucketKey: assessment?.report_path, expiresIn: 300}) : undefined;


    return {assessment,elephant, sectionAs, sectionBs, sectionCs, sectionCIoAs, changes, reportUrl, finalevaluation};
}

export default function AssessmentDashboardPage({title = "Assessment",...props}) {
    const {setSuccess, setError} = useMessage();
    const theme = useTheme();
    const params = useParams();
    const revalidator = useRevalidator();
    console.log("assessment dashboard id: ", params.assessmentId);
    const navigate = useNavigate();
    const {confirm} = useConfirmationDialog();
    const {isOnline} = useConnectivity();
    const {openChangesModal} = useLayout();

    const {inProgressObservations, addObservation} = useSectionB();


    // const {isLoading, data} = useQuery({
    //     queryKey: ['sectiona','form',params.assessmentId,params.sectionAId],
    //     queryFn: async() => {

    console.log("inProgressObservations: ", inProgressObservations);

    const {assessment, elephant, sectionAs, sectionBs, sectionCs, sectionCIoAs, changes, reportUrl, finalevaluation } = useLoaderData();

    console.log("reportURL: ", reportUrl);

    console.log('assessment', assessment);
    console.log('final evaluation',finalevaluation);

    const observationInProgressA = inProgressObservation(sectionAs || []);
    const observationInProgressB = inProgressObservation(sectionBs || []);
    const observationInProgressC = inProgressObservation(sectionCs || []);


    const statusSectionA = overallSectionStatus(sectionAs || []);
    const statusSectionB = overallSectionStatus(sectionBs || []);
    const statusSectionC = overallSectionStatus(sectionCs || []);
    const statusSectionCIoA = overallSectionStatus(sectionCIoAs.map((cur)=>{ cur.submitted_on = cur.incident_time; return cur; }));
    const statusFinalEvaluation = finalEvaluationStatus(finalevaluation);
    const openChanges = allOpenElephantChanges(elephant, changes || []);
    openChanges.sort(sortDescStart);

    console.log("in progress A: ",observationInProgressA);
    console.log("in progress B: ",observationInProgressB);


    // handles the 3 dot menu operations
    const [anchorEl,setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
    }


    // handle the final submission of an assessment
    const handleStartFinalEvaluation = async () => {
        navigate("/assessments/" + assessment.id + "/finalevaluation");
    }

    const isInProgressStatus = isInProgress(assessment);

    const isFinalEvaluationActive = (statusSectionA === "Completed" || statusSectionB === "Completed") ? true : false

    const handleNewSectionA = async() => {
        console.log("click section a");
        navigate("/assessments/" + assessment.id + "/sectiona");
    };

    const handleContinueSectionA = (observation) => {
        console.log("click section a");
        navigate("/assessments/" + assessment.id + "/sectiona/" + observation.id);
    };

    const handleCompleteSectionA = async() => {
        try {
            confirm({
                title: "Submit Section A",
                message: <p style={{margin:0}}>
                    You are about to submit Section A.
                    <br/>
                    Are you sure?
                    <p style={{color:"red"}}>
                        This action cannot be undone
                    </p>
                </p>,
                onSubmit: async () => {
                    const completeResult = await completeSectionA(assessment.id,isOnline);
                    console.log(completeResult);
                    setSuccess("Marked Section A Complete");
                    revalidator.revalidate();
                },
            });
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };

    const handleNewSectionB = async() => {
        console.log("click section b");
        navigate("/assessments/" + assessment.id + "/sectionb");
    };
    const handleNewSectionC = async() => {
        console.log("click section c");
        navigate("/assessments/" + assessment.id + "/sectionc");
    };


    const handleContinueSectionB = (observation) => {
        console.log("click section b");
        navigate("/assessments/" + assessment.id + "/sectionb/" + observation.id);
    };
    const handleContinueSectionC = (observation) => {
        console.log("click section c");
        console.log(observation);
        navigate("/assessments/" + assessment.id + "/sectionc/" + observation.id);
    };

    const handleCompleteSectionB = async() => {
        try {
            confirm({
                title: "Submit Section B",
                message: <p style={{margin:0}}>
                    You are about to submit Section B.
                    <br/>
                    Are you sure?
                    <p style={{color:"red"}}>
                        This action cannot be undone
                    </p>
                </p>,
                onSubmit: async () => {
                    const completeResult = await completeSectionB(assessment.id,isOnline);
                    console.log(completeResult);
                    setSuccess("Marked Section B Complete");
                    revalidator.revalidate();
                },
            });
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };

    const handleCompleteSectionC = async() => {
        /*
        try {
            const completeResult = await completeSectionC(assessment.id,isOnline);
            console.log(completeResult);
            setSuccess("Marked Section C Complete");
        } catch (error) {
            console.error(error);
            setError(error);
        }
        */

        try {
            confirm({
                title: "Submit Section C",
                message: <p style={{margin:0}}>
                    You are about to submit Section C.
                    <br/>
                    Are you sure?
                    <p style={{color:"red"}}>
                        This action cannot be undone
                    </p>
                </p>,
                onSubmit: async () => {
                    const completeResult = await completeSectionC(assessment.id,isOnline);
                    console.log(completeResult);
                    setSuccess("Marked Section C Complete");
                    revalidator.revalidate();
                },
            });
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };

    const handleSectionCIncidentsOfAggression = async() => {
        navigate("/assessments/" + assessment.id + "/sectionc/incidents_of_aggression");
    }

    const handleCompleteSectionCIoA = async() => {
        console.log("section c ioa complete");
        try {
            confirm({
                title: "Submit Section C Incidents of Aggression",
                message: <p style={{margin:0}}>
                    You are about to submit Section C Incidents of Aggression.
                    <br/>
                    Are you sure?
                    <p style={{color:"red"}}>
                        This action cannot be undone
                    </p>
                </p>,
                onSubmit: async () => {
                    const completeResult = await completeSectionCIoA(assessment.id,isOnline);
                    console.log(completeResult);
                    setSuccess("Marked Section C: Incidents of Aggression Complete");
                    revalidator.revalidate();
                },
            });

        } catch (error) {
            console.error(error);
            setError(error);
        }
    }
    const handleNoSectionCIoA = async() => {
        console.log("No section c IoA registered");
        try {

            confirm({
                title: "Close Incidents of Aggression?",
                message: <p style={{margin:0}}>
                You are about to close this section without registering any Incidents of Aggression
                <br/>
                Are you sure?
                <p style={{color:"red"}}>
                    This action cannot be undone
                </p>
            </p>,
                onSubmit: async () => {

                    const tempAssessment = {...assessment};
                    tempAssessment.no_incidents_of_aggression = true;

                    let result=await saveAssessment(tempAssessment,tempAssessment.facility_id,tempAssessment.user_id,isOnline);
                    console.log("result",result);
                    setSuccess("Closed section without any Incidents of Aggression");
                    revalidator.revalidate();
                },
            });

        } catch (error) {
            console.error(error);
            setError(error);
        }
    }

    const handleAddChange = () => {
        openChangesModal({elephant});
    };

    const handleAddObservation = (observation) => {
        addObservation({
            id: observation.id,
            assessmentId: assessment.id,
            title: elephant.name
        });
        setSuccess("Added section B observation to clipboard");
        navigate("/assessments/");
    };


    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            {sectionAs && sectionBs && sectionCs && <>
            <Card>
                <CardContent>
                    <CardHeader 
                        title={elephant.name} 
                        subheader={elephant.studbook_number} 
                        action={
                        <IconButton aria-label="settings" onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        }
                    />

                    <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid xs={4} sm={4}> 
                            <Typography variant="body1" color="secondary">Status</Typography>    
                            <Typography variant="body2">{isInProgressStatus ? "In Progress" : "Submitted"}</Typography>    
                        </Grid>
                        <Grid xs={4} sm={4}> 
                            <Typography variant="body1" color="secondary">Started</Typography>    
                            <Typography variant="body2">{assessment?.started_on?.format(dateLocaleFormat())}</Typography>    
                        </Grid>
                        <Grid xs={4} sm={4}> 
                            <Typography variant="body1" color="secondary">Submitted</Typography>    
                            <Typography variant="body2">{assessment?.submitted_on?.format(dateLocaleFormat())}</Typography>    
                        </Grid>
                    </Grid>

                    {reportUrl && 
                    <>
                        <Subheader label="EWA Report" />
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid xs={12} sm={6}> 
                                {isOnline && <>
                                    <a href={reportUrl} target="_blank" rel="noopener noreferrer"><Typography variant="body1">{assessment?.report_title}</Typography></a>
                                    <Typography variant="body2">{assessment?.report_range}</Typography>
                                </>}
                                {!isOnline && <>
                                    <Typography variant="body1">Only available when online</Typography>
                                </>}
                            </Grid>
                        </Grid>
                    </>}

                    <Subheader label="Any New Changes?" />
                    <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid xs={12} sm={6}> 
                            <Button variant="contained" onClick={handleAddChange}>Add Change</Button>
                        </Grid>

                        <Grid xs={12}> 
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        <Stack sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                            <Typography sx={{}}>Changes</Typography>
                                            <Typography sx={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  flexWrap: "wrap",
                                                  justifyContent: "center",
                                                  alignContent: "center",
                                                  alignItems: "center",
                                                  backgroundColor: theme.palette.badge.main,
                                                  color: theme.palette.text.main,
                                                  boxSizing: "border-box",
                                                  minWidth: "20px",
                                                  fontSize: "0.75rem",
                                                  lineHeight: 1,
                                                  padding: "0 6px",
                                                  height: "20px",
                                                  borderRadius: "10px",
                                                  marginLeft: 1,
                                            }}>{openChanges.length}</Typography>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                {openChanges.map((change)=>{
                                    return (
                                        <Grid key={change.id} container columnSpacing={2} rowSpacing={2}>
                                            <Grid xs={12} sm={12}> 
                                            <ChangeSummaryCard change={change} onActionAreaClick={()=>{navigate("/changes/" + change.id);}} />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                    </AccordionDetails>
                                </Accordion>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={2} rowSpacing={2} sx={{marginTop: 2,  alignItems: "center" }}>
                        <Grid xs={6} sm={6}> 
                            <Subheader label="Section A" />
                        </Grid>
                        <Grid xs={6} sm={6} sx={{textAlign: "right"}}> 
                            <Typography variant="body1">{statusSectionA}</Typography>
                        </Grid>

                        {isInProgressStatus && 
                            <>
                                <Grid xs={12} sm={6}> 
                                    {observationInProgressA && <EwpButton onClick={()=>handleContinueSectionA(observationInProgressA)}>Continue</EwpButton>}
                                    {!observationInProgressA && <EwpButton onClick={handleNewSectionA}>Start Observation</EwpButton>}
                                </Grid>

                                <Grid xs={12} sm={6}> 
                                    {statusSectionA === "All Submitted" && 
                                    <EwpButton complete onClick={handleCompleteSectionA}>Complete Section</EwpButton>
                                    }
                                    {statusSectionA !== "All Submitted" &&
                                    <></>
                                    }
                                </Grid>
                            </>
                        }


                        {sectionAs?.length > 0 && sectionAs.map((cur)=>{
                            return (
                                <Grid xs={12} sm={6}> 
                                    {cur.observation_range} ({observationStatus(cur)})
                                </Grid>
                            );
                        })}
                    </Grid>
                    

                    <Grid container columnSpacing={2} rowSpacing={2} sx={{marginTop: 2,  alignItems: "center" }}>
                        <Grid xs={6} sm={6}> 
                            <Subheader label="Section B" />
                        </Grid>
                        <Grid xs={6} sm={6} sx={{textAlign: "right"}}> 
                            <Typography variant="body1">{statusSectionB}</Typography>
                        </Grid>

                        {isInProgressStatus && 
                            <>
                                <Grid xs={12} sm={6}> 
                                    {observationInProgressB && <EwpButton onClick={()=>handleContinueSectionB(observationInProgressB)}>Continue Observation</EwpButton>}
                                    {!observationInProgressB && <EwpButton onClick={handleNewSectionB}>Start Observation</EwpButton>}
                                </Grid>

                                    <Grid xs={12} sm={6}> 
                                        {statusSectionB === "All Submitted" && 
                                        <EwpButton complete onClick={handleCompleteSectionB}>Complete Section</EwpButton>
                                        }
                                        {statusSectionB !== "All Submitted" && 
                                        <></>
                                        }
                                    </Grid>
                            </>
                        }

                        {sectionBs?.length > 0 && sectionBs.map((cur)=>{
                            return (
                                <Grid xs={12} sm={6}> 
                                    <SectionBObservation record={cur} addHandler={()=>{
                                        handleAddObservation(cur);
                                    }} />
                                </Grid>
                            );
                        })}
                    </Grid>


                    <Grid container columnSpacing={2} rowSpacing={2} sx={{marginTop: 2,  alignItems: "center" }}>
                            <Grid alignItems="center" container gap={2} xs={12}>
                            <Subheader label="Section C: Incidents of Aggression" />
                            <CustomTooltip keyString='section_c_incidents_help_text'>
                                <InfoOutlined/>
                            </CustomTooltip>
                            </Grid>
                        <Grid xs={12}  sx={{textAlign: "left"}}> 
                            <Typography variant="body1">{assessment.no_incidents_of_aggression ? "No Incidents Registered" : statusSectionCIoA}</Typography>
                        </Grid>

                        {statusSectionCIoA !== "Completed" && !assessment.no_incidents_of_aggression &&
                            <Grid container rowGap={2} xs={12} sm={6}> 
                                    <EwpButton onClick={handleSectionCIncidentsOfAggression}>{(sectionCIoAs?.length > 0) ? "Continue" : "Start"} Observation</EwpButton>
                                    {
                                        sectionCIoAs.length===0 &&
                                        <EwpButton variant="outlined" onClick={handleNoSectionCIoA}>Close without Incidents</EwpButton>
                                    }
                            </Grid>
                        }
                            <Grid xs={12} sm={6}> 
                                {statusSectionCIoA === "All Submitted" && 
                                <EwpButton complete onClick={handleCompleteSectionCIoA}>Complete Section</EwpButton>
                                }
                                {statusSectionCIoA !== "All Submitted" && 
                                <></>
                                }
                            </Grid>

                        {sectionCIoAs?.length > 0 && sectionCIoAs.map((cur)=>{
                            return (
                                <Grid xs={12} sm={6}> 
                                    {datetime(cur.incident_time)}
                                </Grid>
                            );
                        })}
                    </Grid>


                    <Grid container columnSpacing={2} rowSpacing={2} sx={{marginTop: 2,  alignItems: "center" }}>
                        <Grid xs={6} sm={6}> 
                            <Subheader label="Section C" />
                        </Grid>
                        <Grid xs={6} sm={6} sx={{textAlign: "right"}}> 
                            <Typography variant="body1">{overallSectionStatus(sectionCs)}</Typography>
                        </Grid>

                        {isInProgressStatus && 
                            <>
                                <Grid xs={12} sm={6}> 
                                    {observationInProgressC && <EwpButton onClick={()=>handleContinueSectionC(observationInProgressC)}>Continue Observation</EwpButton>}
                                    {!observationInProgressC && <EwpButton disabled={statusSectionCIoA !== "Completed" && !assessment.no_incidents_of_aggression} onClick={handleNewSectionC}>Start Observation</EwpButton>}
                                </Grid>
                                    <Grid xs={12} sm={6}> 
                                        {statusSectionC === "All Submitted" && 
                                        <EwpButton complete onClick={handleCompleteSectionC}>Complete Section</EwpButton>
                                        }

                                        {statusSectionC !== "All Submitted" && 
                                        <></>
                                        }
                                    </Grid>
                            </>
                        }

                        {sectionCs.length > 0 && [...new Set(sectionCs)].sort((a, b) => {
                            return new Date(`1970/01/01 ${a.observation_range}`) - new Date(`1970/01/01 ${b.observation_range}`);
                        }).map((cur)=>{
                            return (
                                <Grid xs={12} sm={6}> 
                                    {cur.observation_range} ({observationStatus(cur)})
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Grid container columnSpacing={2} rowSpacing={2} sx={{marginTop: 2,  alignItems: "center" }}>
                        <Grid xs={6} sm={6}> 
                            <Subheader label="Final Evaluation" />
                        </Grid>
                        <Grid xs={6} sm={6} sx={{textAlign: "right"}}> 
                            <Typography variant="body1">{statusFinalEvaluation}</Typography>
                        </Grid>

                        {isInProgressStatus && 
                            <>
                                <Grid xs={12} sm={6}> 
                                    <EwpButton onClick={handleStartFinalEvaluation} disabled={(isFinalEvaluationActive) ? false : true}>Start </EwpButton>
                                </Grid>
                            </>
                        }
                    </Grid>


                </CardContent>
            </Card>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleStartFinalEvaluation} disabled={(isFinalEvaluationActive) ? false : true}>Start Final Evaluation</MenuItem>
            </Menu>
            </>}
        </Container>
    );
}