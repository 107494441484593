import * as React from "react";

import { useLoaderData, useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";

import Subheader from "../components/Subheader";
import Subtitle from "../components/Subtitle";
import ElephantDetails from "../components/elephant/ElephantDetails";
import ElephantRelationships from "../components/elephant/ElephantRelationships";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AssessmentCard from "../components/cards/AssessmentCard";

import { getElephant, listElephants } from "../models/elephants";
import { listAssessmentsByElephant } from "../models/assessments";
import { Grid, Tab, Tabs } from "@mui/material";
import TabPanel from "../components/TabPanel";
import { listChanges } from "../models/changes";
import { allElephantChanges } from "../utils/changes";
import ChangeSummaryCard from "../components/cards/ChangeSummaryCard";


import { useLayout } from "../components/layout/Layout";

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({ params }) {
  console.log("loader params", params);
  const elephant = await getElephant(params.elephantId);
  const assessments = await listAssessmentsByElephant(elephant.id);
  const changes= await listChanges();
  const elephants = await listElephants();
  return { elephant, elephants,changes, assessments };
}


export default function TemplatePage({ title = "EWP", ...props }) {
  const navigate = useNavigate();
  const {openChangesModal, smAndLarger} = useLayout();

  const [selectedTab, setSelectedTab] = React.useState(0);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // used to read the loader data that is returned from the function above
  const { elephant, elephants,changes, assessments } = useLoaderData();
  const elephantChanges = allElephantChanges(elephant,changes);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const openElephantChanges = elephantChanges.filter((cur)=>{
    return cur.is_resolved === false;
  });

  const resolvedElephantChanges = elephantChanges.filter((cur)=>{
    return cur.is_resolved === true;
  });


  // assessment sorting
  const sortedAssessments = assessments
  ? [...assessments].sort((a, b) => {
      // Sort by status (completed first)
      if (!a.submitted_on && b.submitted_on) {
        return -1; 
      } else if (a.submitted_on && !b.submitted_on) {
        return 1;
      }
      // If both assessments have the same status, sort by most recent first
      return new Date(b.created_at) - new Date(a.created_at);
    })
  : []; 


  const openAssessments = sortedAssessments.filter((cur)=>{
    return cur.submitted_on === null;
  });

  const completeAssessments = sortedAssessments.filter((cur)=>{
    return cur.submitted_on !== null;
  });



  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    navigate("/elephants/form/" + elephant.id);
  };

  const handleRelationshipsClick = () => {
    navigate("/elephants/" + elephant.id + "/relationships");
  };

  const handleDepartureDeath = () => {
    navigate("/elephants/" + elephant.id + "/departure");
  }

  const handleAddChange = () => {
    openChangesModal({elephant});
  }


  const navigateToAssessment = (id) => {
    console.log("assessment id: ", id);
    navigate("/assessments/" + id);
  }

  // if you're going to do a form submission, this is your starting point for the submit behavior
  /*
    const onSubmit = async (data) => {
        console.log(data);
    }
    */

  // note you can play with the container maxwidth to spread out the window content appears in
  return (
    <Container component="main" maxWidth="sm">
      <Card>
        <CardContent>
          <CardHeader
            title={elephant.name}
            subheader={elephant.studbook_number}
            action={
              <IconButton aria-label="settings" onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
            }
          />
          <Tabs value={selectedTab} onChange={handleTabChange} orientation={smAndLarger ? "horizontal" : "vertical"}>
            <Tab label="Details" />
            <Tab label="Relationships" />
            <Tab label="Changes" />
            <Tab label="Asssessments" />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <Subheader label="Details" />
            <ElephantDetails elephant={elephant} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Subheader label="Relationships" />
            <ElephantRelationships elephant={elephant} elephants={elephants} />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <Subheader label="Ongoing Changes" />
            <Grid rowGap={2} container>
            {
                openElephantChanges.map((cur)=>{
                    return (
                    <Grid xs={12}>
                        <ChangeSummaryCard change={cur} key={cur.id} onActionAreaClick={()=>{navigate("/changes/" + cur.id);}}/>
                    </Grid>
                    )
                })
            }
            </Grid>
            <Subheader label="Resolved Changes" />
            <Grid rowGap={2} container>
            {
                resolvedElephantChanges.map((cur)=>{
                    return (
                    <Grid xs={12}>
                        <ChangeSummaryCard change={cur} key={cur.id} onActionAreaClick={()=>{navigate("/changes/" + cur.id);}}/>
                    </Grid>
                    )
                })
            }
            </Grid>
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Subheader label="Assessments" />

            <Subtitle sx={{textAlign: "center", marginY: 2}}>Open</Subtitle>
            <Grid rowGap={2} container>
              {openAssessments.map((assessment)=>{
                return (
                  <Grid key={assessment.id} xs={12}>
                    <AssessmentCard
                      elephant={elephant}
                      assessment={assessment}
                      changes={changes}
                      onActionAreaClick={() => {
                        navigateToAssessment(assessment.id);
                      }}
                    />
                  </Grid>
                );

              })}
            </Grid>

            <Subtitle sx={{textAlign: "center", marginY: 2}}>Complete</Subtitle>
            <Grid rowGap={2} container>
              {completeAssessments.map((assessment)=>{
                return (
                  <Grid key={assessment.id} xs={12}>
                    <AssessmentCard
                      elephant={elephant}
                      assessment={assessment}
                      changes={changes}
                      onActionAreaClick={() => {
                        navigateToAssessment(assessment.id);
                      }}
                    />
                  </Grid>
                );

              })}


            </Grid>

          </TabPanel>
        </CardContent>
      </Card>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleRelationshipsClick}>Relationships</MenuItem>
        <MenuItem onClick={handleAddChange}>Add Change</MenuItem>
        <MenuItem onClick={handleDepartureDeath}>Departure/Death</MenuItem>
      </Menu>
    </Container>
  );
}
