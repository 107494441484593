import * as React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Tooltip } from '@mui/material';

import {date} from "../../utils/datetime";

import { useLayout } from '../layout/Layout';

const ellipseText = (inputString,limit, ellipseString) => {
    ellipseString = ellipseString || "...";
    limit = limit || 30;

    return (inputString.length > limit) ? inputString.substring(0,limit) + ellipseString : inputString;
};

export default function UserDetails({user,...props}) {

    const {smAndLarger} = useLayout();

    console.log(user);

    return (
        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Job Title</Typography>    
                <Typography variant="body2">{user.job_title}</Typography>    
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Phone</Typography>    
                <Typography variant="body2">{user.phone}</Typography>    
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Email</Typography>    
                <Tooltip title={user.email}><Typography variant="body2">{ellipseText(user.email,(smAndLarger) ? 30 : 20)}</Typography></Tooltip>
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Role</Typography>    
                <Typography variant="body2">{user.role}</Typography>    
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Begain Working With Elephants On</Typography>    
                <Typography variant="body2">{date(user.began_working_with_elephants_at)}</Typography>    
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Begain Working At Institution On</Typography>    
                <Typography variant="body2">{date(user.began_working_in_facility_at)}</Typography>    
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Active</Typography>    
                <Typography variant="body2">{(user.is_active) ? "Yes" : "No"}</Typography>    
            </Grid>

            {user.departure_date && <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Departed Institution</Typography>    
                <Typography variant="body2">{date(user.departure_date)}</Typography>    
            </Grid>}
        </Grid>
    );
}