import {lookup, keys, recordsNotInKeyList, recordsEqualByField} from "./lists";

const syncRecordsets = async({remoteData,localData,remoteWriteFunc,localWriteFunc,remoteWriteFuncArgs = [], localWriteFuncArgs = [],remoteLookupField = 'id', localLookupField = 'id', remoteKeyField = 'id', localKeyField = 'id', deletedData = [], localDeleteFunc}) => {
    const results = {
        localWrites: [],
        remoteWrites: [],
        syncsEqual: []
    };

    console.log(typeof remoteData);
    console.log(Array.isArray(remoteData));
    if (Array.isArray(remoteData) === false) throw new Error("remoteData needs to be set as an array of records");
    if (Array.isArray(localData) === false) throw new Error("localData needs to be set as an array of records");
    if (typeof remoteWriteFunc !== "function") throw new Error("remoteWriteFunc needs to be set to a function that writes supabase records");
    if (typeof localWriteFunc !== "function") throw new Error("localWriteFunc needs to be set to a function that writes supabase records");

    console.log(remoteData);
    console.log(localData);


    /*
    if (deletedData.length > 0 && typeof localDeleteFunc === "function") {

        // remote data can contain less of what is in local data because of deletes
        // get a list of deleted records from remote too
        console.log("deletedData from server", deletedData);
        const deletedIds = deletedData.map((cur)=>{
            return cur.deleted_record_id || '';
        });

        // remove localData records present in the remote deleted records

        const modifiedLocalData = localData.filter((cur)=>{
            return deletedIds.includes(cur[localKeyField]) === false;
        });
        console.log("modifiedLocalData", modifiedLocalData);


        const idsToDelete = localData.filter((cur)=>{
            return deletedIds.includes(cur[localKeyField]) === true;
        }).map((cur)=>{
            return cur.id;
        });
        console.log("idsToDelete",idsToDelete);

        // remove the localData records that were deleted remotely

        const deleteResults = await Promise.all(idsToDelete.map((id)=>{
            return localDeleteFunc(id);
        }));
        console.log("deleteResults", deleteResults);

        // assign modified records to localData
        localData = modifiedLocalData;
        // perform the rest of the sync as normal

    }
    */

    
    const remoteLookup = lookup(remoteData,remoteLookupField);
    const localLookup = lookup(localData,localLookupField);

    const remoteKeys = keys(remoteData,remoteKeyField);
    const localKeys = keys(localData,localKeyField);
    console.log(remoteKeys);
    console.log(localKeys);
    
    const missingRemoteData = recordsNotInKeyList(remoteData,localKeys);
    const missingLocalData = recordsNotInKeyList(localData,remoteKeys);
    console.log(missingRemoteData);
    console.log(missingLocalData);

    if (missingRemoteData.length > 0) {
        const insertResults = await Promise.all(missingRemoteData.map((cur)=>{
            return localWriteFunc(cur,...localWriteFuncArgs);
        }));
        console.log(insertResults);
        results.localWrites = results.localWrites.concat(insertResults.map((cur)=>cur.id));
    }

    if (missingLocalData.length > 0) {
        const insertResults = await Promise.all(missingLocalData.map((cur)=>{
            return remoteWriteFunc(cur,...remoteWriteFuncArgs);
        }));
        console.log(insertResults);
        results.remoteWrites = results.remoteWrites.concat(insertResults.map((cur)=>cur.id));
    }

    const newerLocalData = localData.filter((cur)=>{
        const record = remoteLookup[cur.id] || null;
        return (record && cur.sync > record.sync);
    });

    const newerRemoteData = remoteData.filter((cur)=>{
        const record = localLookup[cur.id] || null;
        return (record && cur.sync > record.sync);
    });

    if (newerLocalData.length > 0) {
        const updateResults = await Promise.all(newerLocalData.map((cur)=>{
            return remoteWriteFunc(cur,...remoteWriteFuncArgs);
        }));
        results.remoteWrites = results.remoteWrites.concat((updateResults.map((cur)=>cur.id)));
    }

    if (newerRemoteData.length > 0) {
        const updateResults = await Promise.all(newerRemoteData.map((cur)=>{
            return localWriteFunc(cur,...localWriteFuncArgs);
        }));
        results.localWrites = results.localWrites.concat((updateResults.map((cur)=>cur.id)));
    }

    const equalRecords = recordsEqualByField(remoteData,localData);
    console.log("equal records: ", equalRecords);
    results.syncsEqual = results.syncsEqual.concat(equalRecords.map((cur)=>cur.id));

    return results;
}

const handleRemoteDeletions = async({tableName,listFunc,supabase,dbTable, idField = 'id'}) => {
    try {
        const { data, error } = await supabase
            .from('deleted_records')
            .select('deleted_record_id')
            .eq('table_name',tableName);
        if (error) throw new Error(`Error ${error.code}: ${error.message}`);

        const deletedIds = ((data !== null) ? data : []).map((cur)=>{
            return cur.deleted_record_id;
        });

        console.log("deleted IDs", deletedIds);

        const localData = await listFunc() || []; // local data

        const idsToDelete = localData.filter((cur)=>{
            return deletedIds.includes(cur[idField]) === true;
        }).map((cur)=>{
            return cur.id;
        });
        console.log("idsToDelete",idsToDelete);

        // remove the localData records that were deleted remotely
        const deleteResults = await Promise.all(idsToDelete.map(async (id)=>{
            return {id, result: await dbTable.delete(id)};
        }));
        console.log("deleteResults", deleteResults);

        return deleteResults;

    } catch (error) {
        console.error(error);
        throw error
    }

}

export {syncRecordsets, handleRemoteDeletions};