import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import { errorMessage } from '../utils/errors';
import { today, syncvalue, dateFromStorage, dateToStorage} from "../utils/datetime";
import { syncRecordsets, handleRemoteDeletions } from '../utils/sync';

const template = () => {
    const record = {
        id: null,
        assessment_id: null,
        user_id: null,
        observed_on: null,
        observation_range: null,
        submitted_on: null,
        completed_on: null,
        other_elephants_nearby: [], 
        content: 0, 
        uncomfortable: 0,
        relaxed: 0, 
        agitated: 0, 
        tense: 0, 
        frustrated: 0, 
        wary: 0,
        playful: 0,
        sociable: 0,
        lively: 0,
        lethargic: 0,
    };

    return {...record};
}


const fromSupabase = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateFromStorage(temp['observed_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    return temp;
}

const toSupabase = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateToStorage(temp['observed_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    return {sectiona: temp};
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateFromStorage(temp['observed_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    return temp;
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateToStorage(temp['observed_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    return temp;
}


const newSectionA = () =>  {
    return template();
};

const resetSectionARecord = (inputRecord) => {
    const newRecord = newSectionA();
    const {id, assessment_id, user_id, observed_on, observation_range} = inputRecord;
    return {...newRecord,id, assessment_id, user_id, observed_on, observation_range};
}


const listSectionAsByAssessment = async(assessmentId) => {
    try {

        const temp = await db().sectiona.where("assessment_id").equals(assessmentId).sortBy('observed_on');
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionAs = async() => {
    try {

        const temp = await db().sectiona.toCollection().toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionAsBySync = async(lastSync) => {
    try {

        //const temp = await db().sectiona.where('id').equals(id).first();
        const temp = await db().sectiona.where('sync').aboveOrEqual(lastSync).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}


const fetchSectionAs = async() => {
    try {
        const fetchResults = await supabase
            .from('section_a')
            .select('*')
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const fetchSectionAsBySync = async(lastSync) => {
    try {
        const fetchResults = await supabase
            .from('section_a')
            .select('*')
            .gte('sync',lastSync)
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const getSectionA = async(id) => {
    try {

        const temp = await db().sectiona.where('id').equals(id).first();
        console.log(temp);
        return fromDexie(temp);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchSectionA = async(id) => {

    try {
        const fetchResults = await supabase
            .from('section_a')
            .select('*')
            .eq('id', id);
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fromSupabase(fetchResults.data[0]);
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const writeSectionAToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().sectiona.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const writeSectionAToSupabase = async(inputRecord) => {
    try {
        const {sectiona} = toSupabase(inputRecord);

        console.log(sectiona);

        const upsertResult = await supabase.from("section_a").upsert(sectiona);
        if (upsertResult.error) throw new Error(errorMessage(upsertResult.error));
        return {upsertResult, id: sectiona.id};

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const saveSectionA = async(inputRecord,isOnline) => {

    const syncValue = syncvalue();

    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;

    const dexieResults = await writeSectionAToDexie(inputRecord);
    const supabaseResults = (isOnline) ? await writeSectionAToSupabase(inputRecord) : {status: "offline"};
    return {dexieResults, supabaseResults, id: inputRecord.id};
}

const saveSectionALocal = async(inputRecord) => {

    const syncValue = syncvalue();

    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;

    const dexieResults = await writeSectionAToDexie(inputRecord);
    return {dexieResults, id: inputRecord.id};
}

const completeSectionA = async(assessmentId,isOnline) => {
    try {

        const completedOn = today();
        const sectionAs = await listSectionAsByAssessment(assessmentId);

        const results = await Promise.all(sectionAs.map((cur)=>{
            cur.completed_on = completedOn;
            return saveSectionA(cur,isOnline);
        }));

        return results;

    } catch (error) {
        throw error;
    }
}

const sync = async({lastSync = null}) => {

    const deletedRecords = await handleRemoteDeletions({tableName: "section_a", listFunc: listSectionAs, supabase, dbTable: db().sectiona});
    console.log("deletedRecords", deletedRecords);

    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({
        remoteData,
        localData, 
        remoteWriteFunc: writeSectionAToSupabase, 
        localWriteFunc: writeSectionAToDexie, 
    });
    return results;
}

const syncFullRecordSets = async () => {
    const remoteData = await fetchSectionAs() || [];
    const localData = await listSectionAs() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchSectionAsBySync(lastSync) || [];
    const localData = await listSectionAsBySync(lastSync) || [];

    return {remoteData, localData};
};


const truncateDexie = async() => {
    return await db().sectiona.clear();
}


export {newSectionA, resetSectionARecord, listSectionAs, listSectionAsByAssessment, getSectionA, fetchSectionAs, fetchSectionA, saveSectionA, saveSectionALocal, completeSectionA, sync, truncateDexie};
