const isInProgress = (assessment) => {
	return (assessment.started_on && !assessment.submitted_on) ? true : false;
}

/*
Returns:
{
	"elephant_id": {
		"elephant": Object,
		"assessment: Object || null
	}
}
*/
const getElephantLastAssessments = (elephants,assessments) => {
	const elephantStatus = elephants.reduce((acc,cur)=>{
		acc[cur.id] = {
			assessment: null,
			elephant: cur
		};
		return acc;
	},{});

	const elephantLastAssessments = assessments.reduce((acc,cur)=>{
		const record = acc[cur.elephant_id];
		if (record.assessment === null || cur.started_on > record.assessment.started_on) {
			acc[cur.elephant_id].assessment = cur;
		}
		return acc;
	},elephantStatus);

	return elephantLastAssessments;
}

const otherElephantsNearby = (elephants,filterElephant) => {
	return elephants.filter((cur)=>{
		return cur.id !== filterElephant.id;
	}).map((cur)=>{ 
		return {label: cur.name, value: cur.id};
	});
}


export {isInProgress, getElephantLastAssessments, otherElephantsNearby};