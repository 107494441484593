import * as React from 'react';

import {Link, useLoaderData, useNavigate} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { EditOutlined } from "@mui/icons-material";
import FormTextField from '../components/form/FormTextField';
import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormButton from '../components/form/FormButton';
import FormDropDown from '../components/form/FormDropDown';

import Header from "../components/Header";
import Subheader from "../components/Subheader";

import { getElephant, listElephants, saveElephant } from '../models/elephants';
import {relationshipTypeList} from "../models/categories";
import GoBack from '../components/GoBack';
import {v4 as uuidv4} from 'uuid';
import { Button, Typography } from '@mui/material';

import { useConnectivity } from '../context/ConnectivityProvider';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    console.log(params);
    if (!params.elephantId) throw new Error("Need to pick a defined elephant first");
    const elephants = await listElephants();
    const elephant = await getElephant(params.elephantId);
    return {elephants, elephant, relationshipTypes:  await relationshipTypeList()};
}

export default function ElephantRelationshipsForm({title = "EWP",...props}) {
    const {setSuccess, setError} = useMessage();
    const [isEditing, setIsEditing] = React.useState(false)
    const {facilityId} = useAuth();
    const {isOnline} = useConnectivity();
    const navigate = useNavigate();

    console.log("facilityId: ", facilityId);
    // used to read the loader data that is returned from the function above
    const {elephants, elephant, relationshipTypes} = useLoaderData(); 
    console.log("Elephant Record: ", elephant);

    const [list, setList] = React.useState(elephant.relationships);


    // build the elephant list to not have the elephant in it
    const elephantList = elephants.map((cur)=>{
        return {
            label: cur.name,
            value: cur.id
        };
    }).filter((cur)=>{
        return cur.value !== elephant.id;
    });

    console.log(elephantList);

    // handling an edit save
    const handleEdit = async (data) => {
        try {
            console.log(data);
            console.log(list);

            const temp = [...list.filter((cur)=>{
                return cur.id !== data.id;
            }),data];

            console.log(temp);
            setList(temp);
            elephant.relationships = temp;
            await saveElephant(elephant,facilityId,isOnline);
            setSuccess("Elephant Saved");
        } catch (error) {
            setError(error);
        }
    }

    // handle a remove
    const handleDelete = async(data)=>{
        try {
            console.log("delete", data);

            const temp = [...list.filter((cur)=>{
                return cur.id !== data.id;
            })];

            console.log(temp);
            setList(temp);
            elephant.relationships = temp;
            await saveElephant(elephant,facilityId, isOnline);
            setSuccess("Elephant Saved");
        } catch (error) {
            setError(error);
        }
    }


    // handle an add
    const handleAdd= async (data) => {
        try {

            data.id = uuidv4(); // have to assign the ID here, since we need it for filtering, if they add, then edit or remove
            data.elephant_id = elephant.id;
            const temp = [...list,data]

            console.log(temp);
            setList(temp);
            elephant.relationships = temp;
            await saveElephant(elephant,facilityId,isOnline);
            setSuccess("Elephant Saved");
        } catch (error) {
            setError(error);
        }

    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <GoBack onClick={()=>navigate(`/elephants/${elephant.id}`)} />
            <Header label={title} />

            <Subheader label={elephant.name} />

            <Subheader label="New Relationship" />

            <FormProvider onSubmit={handleAdd} defaultValues={{related_elephant_id: '', relationship_type: ''}} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={6} sm={6} display="flex" justifyContent="center" alignItems="center"> 
                            <FormDropDown name="related_elephant_id" label="Related Elephant" list={elephantList} required />
                        </Grid>
                        <Grid xs={6} sm={6} display="flex" justifyContent="center" alignItems="center"> 
                            <FormDropDown name="relationship_type" label="Relationship Type" list={relationshipTypes} required />
                        </Grid>
                        <Grid xs={12} sm={12} display="flex" justifyContent="center" alignItems="center"> 
                            <FormTextField name="description" label="Description"/>
                        </Grid>
                        <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
                            <FormButton>Add</FormButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormProvider>
            <Grid container alignItems={"center"} justifyContent="space-between">
                <Subheader label="Existing Relationships" />
                <Button onClick={()=>{setIsEditing(!isEditing)}} variant={!isEditing?'contained':'outlined'}>
                <EditOutlined/>
                </Button>
            </Grid>
           {
            isEditing?
            list.map((relation)=>

                <FormProvider key={relation.id} defaultValues={relation} >
                    <Form>
                        <Grid container spacing={2} sx={{mb:2}}>
                            <Grid xs={6} sm={6} display="flex" justifyContent="center" alignItems="center"> 
                                <FormDropDown name="related_elephant_id" label="Related Elephant" list={elephantList} required />
                            </Grid>
                            <Grid xs={6} sm={6} display="flex" justifyContent="center" alignItems="center"> 
                                <FormDropDown name="relationship_type" label="Relationship Type" list={relationshipTypes} required />
                            </Grid>
                            <Grid xs={12} sm={12} display="flex" justifyContent="center" alignItems="center"> 
                                <FormTextField name="description" label="Description"/>
                            </Grid>
                            <Grid xs={4} display="flex" justifyContent="center" alignItems="center">
                                <FormButton onSubmit={handleEdit}>Save</FormButton>
                            </Grid>
                            <Grid xs={4} display="flex" justifyContent="center" alignItems="center">
                                <FormButton onSubmit={handleDelete}>Delete</FormButton>
                            </Grid>
                        </Grid>
                    </Form>
                </FormProvider>
            )
            :
            <>
            <Grid container display="flex" sx={{pl:2, pr:2,mt:2}} justifyContent="space-between" alignItems="center">
            <Grid xs={9}display="flex" alignItems="center">
                    <Typography fontWeight="bold" sx={{color:""}}>Name</Typography>
                </Grid>
                <Grid xs={3} display="flex"  alignItems="center">
                    <Typography fontWeight="bold" sx={{color:""}}>Relationship</Typography>
                </Grid>
            </Grid>
            {list.map(el=>
            
            <Grid container justifyContent="space-between" sx={{position:"relative",border:"1px solid #f1f1f1",borderRadius:2, mt:2,mb:2, p:2}}>


                <Grid xs={9}display="flex" alignItems="center">
                    <Link style={{color:"#00554a"}} to={`/elephants/${el.related_elephant_id}`}><Typography>{elephantList.find((cur)=>{return cur.value === el.related_elephant_id}).label}</Typography></Link>
                </Grid>
                <Grid xs={3} display="flex"  alignItems="center">
                    <Typography>{relationshipTypes.find((cur)=>{return cur.value === el.relationship_type})?.label || "not found"}</Typography>
                </Grid>
              {
                el.description&&  
                <>
                    <Typography fontWeight="bold" sx={{fontSize:"0.8rem",mt:2}}>Description</Typography>
                    <Grid xs={12} display="flex"  alignItems="center">
                        <Typography>{el.description}</Typography>
                    </Grid>
                </>
              }
            </Grid>
            )
           }
           </>
}


        </Container>
    );
}