import * as React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CardActionArea } from '@mui/material';

import Avatar from '@mui/material/Avatar';

import ElephantNameList from '../ElephantNameList';

import { useQuery } from '@tanstack/react-query'
import { useTheme } from '@mui/material/styles';
import { useLayout } from "../layout/Layout";

import {categoryList, typeList, statusList} from '../../models/categories';

import {date} from "../../utils/datetime";
import { listLabel } from '../../utils/lists';


export default function ChangeCard({change,elephantLookup,onActionAreaClick,...props}) {

    const {isLoading, data} = useQuery({
        queryKey: ['categories'],
        queryFn: async() => {
            return {
                categories: await categoryList(),
                types: await typeList(),
                statuses: await statusList(),
            };
        },
        networkMode: "offlineFirst",
    });

    const theme = useTheme();
    const {smAndLarger} = useLayout();

    const timeframe = (change.start && change.end) ? date(change.start) + " - " + date(change.end) : "Started " + date(change.start);


    const elephants = change.elephants.filter((cur)=>{
        return Object.keys(elephantLookup).includes(cur);
    }).map((id)=>{
        return elephantLookup[id];
    });
    console.log("impactedElephants",elephants);


    const maxWords = 15;
    const descriptionWords = change.description.split(/\s+/);
    const summary = descriptionWords.slice(0,maxWords).join(' ') + ((descriptionWords.length > maxWords) ? '...' : '');


    const avatarColor = (change.is_resolved) ? theme.palette.completedchange.main : theme.palette.activechange.main;

    const cardStyle = (smAndLarger) ? {height: 300} : {display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100, maxHeight: 500};

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Card sx={cardStyle}>
            {isLoading && <div>Loading</div>}
            {!isLoading && 
            <CardActionArea onClick={onActionAreaClick}>
                <CardHeader 
                    title={listLabel(change.category,data.categories)}  
                    subheader={<><div>{timeframe}</div><div>{listLabel(change.change_type,data.types)},{listLabel(change.status,data.statuses)}</div></>}
                    titleTypographyProps={{variant: "h5"}} 
                    avatar={
                        <Avatar sx={{ bgcolor: avatarColor }}>{change.category.charAt(0).toUpperCase()}</Avatar>
                    }
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid xs={6}> 
                            <Typography variant="body2">{summary}</Typography>
                        </Grid>
                        <Grid xs={6}> 
                            <ElephantNameList elephants={elephants} max={4} />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            }
        </Card>
    );
}