import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import { errorMessage } from '../utils/errors';
import {syncvalue} from "../utils/datetime";
import dayjs from 'dayjs';

import { syncRecordsets, handleRemoteDeletions } from '../utils/sync';

/*
listElephants()
    - this comes from the local DB
getElephant(id)
    - this comes from the local DB
fetchElephant(id)
    - this is a direct call to supabase 

sync()
    - Performs the sync opeation between the local DB and elephants 
*/


const template = () => {
    const record = {
        "id": null,
        "studbook_number": '',
        "name": '',
        "sex": '',
        "genus": '',
        "born_on": null,
        "born_on_is_estimated": false,
        "entered_captivity_on": null,
        "entered_captivity_on_is_estimated": false,
        "died_on": null,
        "arrived_on": null,
        "arrived_on_is_estimated": false,
        "departed_on": null,
        "additional_information_about_departure": null,
        "relationships": [],
    }
    return {...record};
}


const transformFromDb = (inputRecord) => {

    const {facilities_elephants,elephant_relationships,...elephant} = inputRecord;
    const record = template();
    const temp = {...record,...facilities_elephants[0],...elephant};
    if (temp['born_on']) temp['born_on'] = dayjs(temp['born_on']);
    if (temp['arrived_on']) temp['arrived_on'] = dayjs(temp['arrived_on']);
    if (temp['entered_captivity_on']) temp['entered_captivity_on'] = dayjs(temp['entered_captivity_on']);
    if (temp['died_on']) temp['died_on'] = dayjs(temp['died_on']);
    if (temp['departed_on']) temp['departed_on'] = dayjs(temp['departed_on']);
    temp['born_on_is_estimated'] = (temp['born_on_is_esimtated']) ? 1 : 0;
    temp['arrived_on_is_estimated'] = (temp['arrived_on_is_esimtated']) ? 1 : 0;
    temp['entered_captivity_on_is_estimated'] = (temp['entered_captivity_on_is_esimtated']) ? 1 : 0;
    temp['relationships'] = elephant_relationships;
    console.log(temp);
    return temp;
};

const transformToDb = (inputRecord) => {
    const {arrived_on,arrived_on_is_estimated,departed_on,additional_information_about_departure,created_at,updated_at,relationships,...remaining} = inputRecord;
    const fe = {arrived_on,arrived_on_is_estimated,departed_on,additional_information_about_departure};

    if (remaining['born_on'] instanceof dayjs) remaining['born_on'] = remaining['born_on'].toDate();
    if (remaining['entered_captivity_on'] instanceof dayjs) remaining['entered_captivity_on'] = remaining['entered_captivity_on'].toDate();
    if (remaining['died_on'] instanceof dayjs) remaining['died_on'] = remaining['died_on'].toDate();
    remaining['born_on_is_estimated'] = Boolean(remaining['born_on_is_estimated']);
    remaining['entered_captivity_on_is_estimated'] = Boolean(remaining['entered_captivity_on_is_estimated']);

    if (fe['arrived_on'] instanceof dayjs) fe['arrived_on'] = fe['arrived_on'].toDate();
    if (fe['departed_on'] instanceof dayjs) fe['departed_on'] = fe['departed_on'].toDate();

    fe['arrived_on_is_estimated'] = Boolean(fe['arrived_on_is_estimated']);

    return {elephant: remaining, facilitiesElephants: fe, elephantRelationships: relationships};
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    if (temp['born_on'] instanceof dayjs) temp['born_on'] = temp['born_on'].toDate();
    if (temp['entered_captivity_on'] instanceof dayjs) temp['entered_captivity_on'] = temp['entered_captivity_on'].toDate();
    if (temp['died_on'] instanceof dayjs) temp['died_on'] = temp['died_on'].toDate();
    if (temp['arrived_on'] instanceof dayjs) temp['arrived_on'] = temp['arrived_on'].toDate();
    if (temp['departed_on'] instanceof dayjs) temp['departed_on'] = temp['departed_on'].toDate();
    return temp;
}

const fromDexie = (inputRecord) => {

    const temp = {...inputRecord};

    if (temp['born_on'] instanceof Date) temp['born_on'] = dayjs(temp['born_on']);
    if (temp['entered_captivity_on'] instanceof Date) temp['entered_captivity_on'] = dayjs(temp['entered_captivity_on']);
    if (temp['died_on'] instanceof Date) temp['died_on'] = dayjs(temp['died_on']);
    if (temp['arrived_on'] instanceof Date) temp['arrived_on'] = dayjs(temp['arrived_on']);
    if (temp['departed_on'] instanceof Date) temp['departed_on'] = dayjs(temp['departed_on']);
    return temp;
}


const newElephant = () =>  {
    return template();
};

const listElephants = async () => {
    try {
        const temp = await db().elephants.toCollection().toArray();

        return temp.map((cur)=>{
            return fromDexie(cur);
        })

    } catch (error) {
        console.error(error);
        throw error
    }
}

const listElephantsBySync = async (lastSync) => {
    try {
        const temp = await db().elephants.where('sync').aboveOrEqual(lastSync).toArray();

        return temp.map((cur)=>{
            return fromDexie(cur);
        })

    } catch (error) {
        console.error(error);
        throw error
    }
}

const getElephant = async (id) => {
    try {
        const record = await db().elephants.where('id').equals(id).first();
        console.log(record);
        return fromDexie(record);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchElephants = async() => {
    try {
        const { data, error } = await supabase
            .from('elephants')
            .select('*, facilities_elephants (arrived_on, arrived_on_is_estimated, departed_on, additional_information_about_departure), elephant_relationships(*)')
        if (error) throw new Error(`Error ${error.code}: ${error.message}`);
        if (data !== null) {

            return data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
    } catch (error) {
        console.error(error);
        throw error
    }
}

const fetchElephantsBySync = async(lastSync) => {
    try {
        const { data, error } = await supabase
            .from('elephants')
            .select('*, facilities_elephants (arrived_on, arrived_on_is_estimated, departed_on, additional_information_about_departure), elephant_relationships(*)')
            .gte('sync',lastSync);
        if (error) throw new Error(`Error ${error.code}: ${error.message}`);
        if (data !== null) {

            return data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
    } catch (error) {
        console.error(error);
        throw error
    }
}

const fetchElephant = async(elephantId) => {
    try {
        const { data, error } = await supabase
            .from('elephants')
            .select('*, facilities_elephants (arrived_on, arrived_on_is_estimated, departed_on, additional_information_about_departure), elephant_relationships(*)')
            .eq('id',elephantId)
        if (error) throw new Error(`Error ${error.code}: ${error.message}`);
        if (data !== null) {
            return transformFromDb(data[0]);
        }
    } catch (error) {
        console.error(error);
        throw error
    }
}


const writeElephantToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().elephants.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const writeElephantToSupabase = async(inputRecord,facilityId) => {

    try {

        if (!!facilityId === false) throw new Error("facilityID can't be empty when saving an elephant");

        const {elephant, facilitiesElephants, elephantRelationships} = transformToDb(inputRecord);

        console.log("data entities before supabase write", elephant, facilitiesElephants, elephantRelationships);

        // update the records
        elephant.updated_at = new Date();

        facilitiesElephants.elephant_id = elephant.id;
        facilitiesElephants.facility_id = facilityId;
        facilitiesElephants.updated_at = new Date();

        const elephantResult = await supabase.from("elephants").upsert(elephant);
        const facilitiesElephantsResult = await supabase.from("facilities_elephants").upsert(facilitiesElephants);
        const elephantRelationshipsResults = await updateElephantRelationshipsSupabase(elephant,elephantRelationships);

        console.log("supabase upsert results", elephantResult, facilitiesElephantsResult, elephantRelationshipsResults);

        if (elephantResult.error) throw new Error(errorMessage(elephantResult.error));
        if (facilitiesElephantsResult.error) throw new Error(errorMessage(facilitiesElephantsResult.error));
        if (elephantRelationshipsResults.error) throw new Error(errorMessage(elephantRelationshipsResults.error));

        return {elephantResult, facilitiesElephantsResult, elephantRelationshipsResults, id: elephant.id};

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const updateElephantRelationshipsSupabase = async(elephant, relationships) => {

    // get the current list relationships for the current elephant, 
    const rResults = await supabase.from("elephant_relationships").select("*").eq("elephant_id",elephant.id);
    console.log("rResult", rResults);
    if (rResults.error) throw new Error(`Error ${rResults.error.code}: ${rResults.error.message}`);


    const relationshipIds = relationships.map((cur)=>{
        return cur.id;
    });

    // find any records in rResults.data that aren't in relationships and build a list of those for relat
    const relationshipsToDelete = rResults.data.filter((cur)=>{
        return relationshipIds.includes(cur.id) === false;
    });

    console.log("relationshipsToDelete",relationshipsToDelete);


    // see which to delete, upsert the rest. 

    const results = [];

    for (let i = 0; i < relationshipsToDelete.length; i++) {
        const deleteResult = await supabase.from("elephant_relationships").delete().eq('id',relationshipsToDelete[i]['id']);
        if (deleteResult.error) throw new Error(`Error ${deleteResult.error.code}: ${deleteResult.error.message}`);
        results.push(deleteResult);
    }

    for (let i = 0; i < relationships.length; i++) {
        const upsertResult = await supabase.from("elephant_relationships").upsert(relationships[i]);
        if (upsertResult.error) throw new Error(`Error ${upsertResult.error.code}: ${upsertResult.error.message}`);
        results.push(upsertResult);
    }

    console.log(results);
    return results;
}



const saveElephant = async(inputRecord, facilityId, isOnline) => {
    try {

        const syncValue = syncvalue();
        inputRecord.id = inputRecord.id || uuidv4();
        inputRecord.sync = syncValue;

        const dexieResults = await writeElephantToDexie(inputRecord);
        const supabaseResults = (isOnline) ? await writeElephantToSupabase(inputRecord,facilityId) : {status: "offline"};
        return {dexieResults, supabaseResults, id: inputRecord.id};

    } catch (error) {
        console.error(error);
        throw error;
    }

}

const sync = async({facilityId, lastSync = null}) => {
    const deletedRecords = await handleRemoteDeletions({tableName: "elephants", listFunc: listElephants, supabase, dbTable: db().elephants});
    console.log("deletedRecords", deletedRecords);

    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({remoteData,localData, remoteWriteFunc: writeElephantToSupabase, localWriteFunc: writeElephantToDexie, remoteWriteFuncArgs: [facilityId]});
    return results;
};

const syncFullRecordSets = async () => {
    const remoteData = await fetchElephants() || [];
    const localData = await listElephants() || [];

    return {remoteData, localData};
};
const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchElephantsBySync(lastSync) || [];
    const localData = await listElephantsBySync(lastSync) || [];

    return {remoteData, localData};
};

const truncateDexie = async() => {
    return await db().elephants.clear();
}

export {newElephant, listElephants, getElephant, fetchElephants, fetchElephant, saveElephant, sync, truncateDexie}