import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import { errorMessage } from '../utils/errors';
import { syncvalue, dateFromStorage, dateToStorage} from "../utils/datetime";
import { syncRecordsets, handleRemoteDeletions } from '../utils/sync';

const template = () => {
    const record = {
        id: null,
        user_id: null,
        completed_on: null,
        stereotypies: [], //json
        stereotypies_interfere_with_behavior: null,
        can_stereotypies_be_interrupted: null,
        can_stereotypies_be_interrupted_description: null,
        feeding_foraging_score: 0,
        activity_frequency_score: 0,
        social_elephant_score: 0,
        other_observations: null,
        unexpected_situations: null,
        vocalisations: [], //json
        mental_health: 0,
        physical_health: 0,
        overall_welfare: 0,
        final_comments: null,
    };

    return {...record};
}


const transformFromDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    return temp;
}

const transformToDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    return temp;
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    return temp;
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    return temp;
}


const newFinalEvaluation = () =>  {
    return template();
};

const listFinalEvaluations = async() => {
    try {
        const temp = await db().finalevaluations.toCollection().toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listFinalEvaluationsBySync = async(lastSync) => {
    try {

        const temp = await db().finalevaluations.where('sync').aboveOrEqual(lastSync).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}
/*
const listAssessmentsByElephant = async(elephantId) => {
    try {

        //const temp = await db().assessments.where('elephant_id').equals(elephantId).sortBy('started_on').reverse().toArray();
        const temp = await db().assessments.where('elephant_id').equals(elephantId).reverse().sortBy('started_on');
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}
*/

const fetchFinalEvaluations = async() => {
    try {
        const fetchResults = await supabase
            .from('assessment_final_evaluations')
            .select('*')
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const fetchFinalEvaluationsBySync = async(lastSync) => {
    try {
        const fetchResults = await supabase
            .from('assessment_final_evaluations')
            .select('*')
            .gte('sync',lastSync)
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const getFinalEvaluation = async(id) => {
    try {

        const temp = await db().finalevaluations.where('id').equals(id).first();
        console.log(temp);
        return (temp) ? fromDexie(temp) : null;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchFinalEvaluation = async(id) => {

    try {
        const fetchResults = await supabase
            .from('assessment_final_evaluations')
            .select('*')
            .eq('id', id);
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return transformFromDb(fetchResults.data[0]);
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const writeFinalEvaluationToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().finalevaluations.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const writeFinalEvaluationToSupabase = async(inputRecord, userId) => {
    try {

        inputRecord.user_id = inputRecord.user_id || userId;

        const finalevaluation = transformToDb(inputRecord);

        const finalEvaluationResult = await supabase.from("assessment_final_evaluations").upsert(finalevaluation);
        console.log("finalEvaluationResult: ", finalEvaluationResult);
        if (finalEvaluationResult.error) throw new Error(errorMessage(finalEvaluationResult.error));
        return {finalEvaluationResult, id: finalevaluation.id};


    } catch (error) {
        console.error(error);
        throw error;
    }
}

const saveFinalEvaluation = async(inputRecord, userId, isOnline) => {

    const syncValue = syncvalue();

    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.user_id = inputRecord.user_id || userId;
    inputRecord.sync = syncValue;

    const dexieResults = await writeFinalEvaluationToDexie(inputRecord);
    const supabaseResults = (isOnline) ? await writeFinalEvaluationToSupabase(inputRecord,userId) : {status: "offline"};
    return {dexieResults, supabaseResults, id: inputRecord.id};
}

const sync = async({userId, lastSync = null}) => {

    const deletedRecords = await handleRemoteDeletions({tableName: "assessment_final_evaluations", listFunc: listFinalEvaluations, supabase, dbTable: db().finalevaluations});
    console.log("deletedRecords", deletedRecords);

    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({
        remoteData,
        localData, 
        remoteWriteFunc: writeFinalEvaluationToSupabase, 
        localWriteFunc: writeFinalEvaluationToDexie, 
        remoteWriteFuncArgs: [userId]});
    return results;
}

const syncFullRecordSets = async (facilityId) => {
    const remoteData = await fetchFinalEvaluations() || [];
    const localData = await listFinalEvaluations() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchFinalEvaluationsBySync(lastSync) || [];
    const localData = await listFinalEvaluationsBySync(lastSync) || [];

    return {remoteData, localData};
};


const truncateDexie = async() => {
    return await db().finalevaluations.clear();
}


export {newFinalEvaluation, listFinalEvaluations, getFinalEvaluation, fetchFinalEvaluations, fetchFinalEvaluation, saveFinalEvaluation, sync, truncateDexie};
