import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import { errorMessage } from '../utils/errors';
import { today, syncvalue, dateFromStorage, dateToStorage} from "../utils/datetime";
import { syncRecordsets, handleRemoteDeletions } from '../utils/sync';

const template = () => {
    const record = {
        id: null,
        assessment_id: null,
        user_id: null,
        observed_on: null,
        observation_range: null,
        submitted_on: null,
        completed_on: null,
        current_weather_conditions: null, 
        other_elephants_nearby: [], 
        wallowing: 0, 
        feeding: 0, 
        foraging: 0, 
        locomotion: 0, 
        stereotyping: 0, 
        environmental_interaction: 0, 
        affiliative_giving: 0, 
        affiliative_receiving: 0, 
        agonistic_giving: 0, 
        agonistic_receiving: 0, 
        playing_with_others: 0, 
        anticipating: 0, 
        behaviors: [],
        comments: null,
    };

    return {...record};
}

const templateBehavior = () => {
    const record = {
        id: null,
        section_b_id: null, 
        observed_on: null,
        behavior: null,
        description: null,
        location: null,
        associated_elephants: [],
    }
    return record;
}

const fromSupabase = (inputRecord) => {
    //const temp = {...inputRecord};
    const {section_b_behaviors,...temp} = inputRecord;
    temp['observed_on'] = dateFromStorage(temp['observed_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);

    const behaviors = (section_b_behaviors || []).map((cur)=>{
        cur['observed_on'] = dateFromStorage(cur['observed_on']);
        return cur;
    });
    temp['behaviors'] = behaviors;
    return temp;
}

const toSupabase = (inputRecord) => {
    const {behaviors,...temp} = inputRecord;
    temp['observed_on'] = dateToStorage(temp['observed_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);

    const tempBehaviors = behaviors.map((cur)=>{
        cur['observed_on'] = dateToStorage(cur['observed_on']);
        return cur;
    });

    return {sectionb: temp, behaviors: tempBehaviors};
}

const fromDexie = (inputRecord) => {
    const behaviors = inputRecord.behaviors || []
    const temp = {...inputRecord};
    temp['observed_on'] = dateFromStorage(temp['observed_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    temp['behaviors'] = behaviors.map((cur)=>{
        cur['observed_on'] = dateFromStorage(cur['observed_on']);
        return cur;
    });
    return temp;
}

const toDexie = (inputRecord) => {
    const behaviors = inputRecord.behaviors || []
    const temp = {...inputRecord};
    temp['observed_on'] = dateToStorage(temp['observed_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    temp['behaviors'] = behaviors.map((cur)=>{
        cur['observed_on'] = dateToStorage(cur['observed_on']);
        return cur;
    });
    return temp;
}


const newSectionB = () =>  {
    return template();
};

const newSectionBBehavior = () => {
    return templateBehavior();
}

const resetSectionBRecord = (inputRecord) => {
    const newRecord = newSectionB();

    const {id, assessment_id, user_id, observed_on, observation_range} = inputRecord;

    return {...newRecord,id, assessment_id, user_id, observed_on, observation_range};
}

const listSectionBsThatStartedObservationRange = async(observationRange) => {
    try {
        const temp = await db().sectionb.where("observation_range").equals(observationRange).sortBy('observed_on');
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionBsByAssessment = async(assessmentId) => {
    try {

        const temp = await db().sectionb.where("assessment_id").equals(assessmentId).sortBy('observed_on');
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionBs = async() => {
    try {

        const temp = await db().sectionb.toCollection().toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionBsBySync = async(lastSync) => {
    try {

        const temp = await db().sectionb.where("sync").aboveOrEqual(lastSync).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchSectionBs = async() => {
    try {
        const fetchResults = await supabase
            .from('section_b')
            .select('*, section_b_behaviors (*)');
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const fetchSectionBsBySync = async(lastSync) => {
    try {
        const fetchResults = await supabase
            .from('section_b')
            .select('*, section_b_behaviors (*)')
            .gte("sync",lastSync);
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const getSectionB = async(id) => {
    try {

        const temp = await db().sectionb.where('id').equals(id).first();
        console.log(temp);
        return fromDexie(temp);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchSectionB = async(id) => {

    try {
        const fetchResults = await supabase
            .from('section_b')
            .select('*, section_b_behaviors (*)')
            .eq('id',id)

        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fromSupabase(fetchResults.data[0]);
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const writeSectionBToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().sectionb.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/*
const writeNewSectionBToSupabase = async(inputRecord, facilityId) => {
    try {
        const {assessment} = toSupabase(inputRecord);
        assessment.facility_id = facilityId;
        const assessmentResult = await supabase.from("assessments").insert(assessment);
        if (assessmentResult.error) throw new Error(`Error ${assessmentResult.error.code}: ${assessmentResult.error.message}`);
        return {assessmentResult, id: assessment.id};


    } catch (error) {
        console.error(error);
        throw error;
    }
}
*/
const writeSectionBToSupabase = async(inputRecord) => {
    try {
        const {sectionb, behaviors} = toSupabase(inputRecord);

        console.log(sectionb);

        const upsertResult = await supabase.from("section_b").upsert(sectionb);
        if (upsertResult.error) throw new Error(errorMessage(upsertResult.error));

        const behaviorPromises = behaviors.map((cur)=>{
            return supabase.from("section_b_behaviors").upsert(cur);
        });
        const behaviorResults = await Promise.all(behaviorPromises);

        return {upsertResult, behaviorResults, id: sectionb.id};

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const saveSectionB = async(inputRecord, isOnline) => {

    const syncValue = syncvalue();

    const behaviors = inputRecord.behaviors || [];
    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;
    inputRecord.behaviors = behaviors.map((cur)=>{
        cur.id = cur.id || uuidv4();
        cur.sync = syncValue;
        return cur;
    });

    const dexieResults = await writeSectionBToDexie(inputRecord);
    const supabaseResults = (isOnline) ? await writeSectionBToSupabase(inputRecord) : {status: "offline"};
    return {dexieResults, supabaseResults, id: inputRecord.id};
}

const saveSectionBLocal = async(inputRecord) => {

    const syncValue = syncvalue();

    const behaviors = inputRecord.behaviors || [];
    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;
    inputRecord.behaviors = behaviors.map((cur)=>{
        cur.id = cur.id || uuidv4();
        cur.sync = syncValue;
        return cur;
    });

    const dexieResults = await writeSectionBToDexie(inputRecord);
    return {dexieResults, id: inputRecord.id};
}

const completeSectionB = async(assessmentId, isOnline) => {
    try {

        const completedOn = today();
        const sectionBs = await listSectionBsByAssessment(assessmentId);

        const results = await Promise.all(sectionBs.map((cur)=>{
            cur.completed_on = completedOn;
            return saveSectionB(cur,isOnline);
        }));

        return results;

    } catch (error) {
        throw error;
    }
}

const sync = async({lastSync = null}) => {

    const deletedRecords = await handleRemoteDeletions({tableName: "section_b", listFunc: listSectionBs, supabase, dbTable: db().sectionb});
    console.log("deletedRecords", deletedRecords);


    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({
        remoteData,
        localData, 
        remoteWriteFunc: writeSectionBToSupabase, 
        localWriteFunc: writeSectionBToDexie, 
    });
    return results;
}

const syncFullRecordSets = async () => {
    const remoteData = await fetchSectionBs() || [];
    const localData = await listSectionBs() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchSectionBsBySync(lastSync) || [];
    const localData = await listSectionBsBySync(lastSync) || [];

    return {remoteData, localData};
}


const truncateDexie = async() => {
    return await db().sectionb.clear();
}


export {newSectionB, newSectionBBehavior, resetSectionBRecord, listSectionBs, listSectionBsByAssessment, listSectionBsThatStartedObservationRange, getSectionB, fetchSectionBs, fetchSectionB, saveSectionB, saveSectionBLocal, completeSectionB, sync, truncateDexie};
