import * as React from 'react';

import {useNavigate} from 'react-router-dom';
import { useMessage } from '../context/MessageProvider';
import {useLoaderData} from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import TimerIcon from '@mui/icons-material/Timer';

import {FormProvider} from '../components/form/FormProvider';
import FormOnChange from '../components/form/FormOnChange';
import FormButton from '../components/form/FormButton';
import FormMultiSelectChips from '../components/form/FormMultiSelectChips';
import FormSlider from '../components/form/FormSlider';


import Header from "../components/Header";

import { listElephants } from '../models/elephants';
import { getAssessment } from '../models/assessments';
import { resetSectionARecord, getSectionA, saveSectionA, saveSectionALocal } from '../models/sectiona';

import {today} from "../utils/datetime";

import { findModel } from '../utils/model';

import EwpButton from '../components/EwpButton';
import GoBack from '../components/GoBack';

import CustomTooltip from '../components/tooltip/CustomTooltip';
import HelpIcon from '@mui/icons-material/Help';

import Timer from '../components/Timer';


import {useLayout} from '../components/layout/Layout';
import { useConfirmationDialog } from '../context/ConfirmationDialogContext';

import { useConnectivity } from '../context/ConnectivityProvider';
import { useTimer } from '../context/TimerProvider';

import { otherElephantsNearby } from '../utils/assessments';

const CustomSlider = ({name,label,leftLabel,rightLabel,tooltipKey,...props}) => {
    return (
        <FormSlider 
            name={name} 
            label={<Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <Typography color="secondary" sx={{fontWeight: "bold" }}>{label}</Typography>
                    <CustomTooltip keyString={tooltipKey}>
                        <HelpIcon />
                    </CustomTooltip>
                </Stack>}
        
            min={0} 
            max={10} 
            step={0.1} 
            marks={[
                {
                    value: 0,
                    label: leftLabel,
                },
                {
                    value: 10,
                    label: rightLabel,
                },
            ]}
        {...props}
        />
    );
}

export async function loader({params}) {
    const assessment = await getAssessment(params.assessmentId);
    const elephants = await listElephants();
    const sectiona = await getSectionA(params.sectionAId);

    return {assessment, elephants, sectionaRecord: sectiona};
}

export default function AssessmentSectionAFormPage({title = "EWP",...props}) {

    const {setSuccess, setError, setMessage} = useMessage();
    const navigate = useNavigate();
    const {confirm} = useConfirmationDialog();
    const {hideNavigation,showNavigation, smAndLarger} = useLayout();
    const {isOnline} = useConnectivity();

    const {isRunning, startTimer, endTimer} = useTimer();

    const {assessment, elephants, sectionaRecord} = useLoaderData(); 
    const [sectiona, setSectiona] = React.useState(sectionaRecord);


    console.log("assessment: ", assessment, "elephants:", elephants);
    console.log("sectiona: ",sectiona);

    const elephant = findModel(elephants || [],assessment?.elephant_id);

    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async (inputData) => {
        try {
            showNavigation();

            const payload = {...inputData, submitted_on: today()};
            if(inputData['other_elephants_nearby'].includes('None')){
                payload['other_elephants_nearby'] = [];
            }

            const saveResult = await saveSectionA(payload,isOnline);
            console.log("sectiona save: ", saveResult);

            setSuccess("Section Data Saved");
            navigate("/assessments/" + assessment.id);

        } catch (error) {
            console.error(error);
            setError(error);
        }
    }

    const onChange = async (inputData) => {
        console.log("onChange write");
        const saveResult = await saveSectionALocal(inputData);
        console.log(saveResult);
    }


    const [showBackArrow,setShowBackArrow] = React.useState(true);
    const [showForm,setShowForm] = React.useState(false);


    const toggleTimer = async () => {
        if (!isRunning) {
            // starts the timer
            startTimer(60)
            hideNavigation();
            setShowBackArrow(false);
            // show the main form
            setShowForm(true);
            // set record.observed_on to the curren timestamp locally
            setSectiona({...sectiona, observed_on: today()});

        } else {
            // prompt to confirm
            confirm({
                title: "Reset Observation?",
                message: "Confirming will cause the timer to stop and reset, but this also resets the observation data for this observation",
                onSubmit: async () => {
                    // reset the record
                    const temp = resetSectionARecord(sectiona);
                    setSectiona({...temp});
                    onChange(temp);

                    // hides the form
                    setShowForm(false);
                    
                    // reset the timer
                    resetTimer();
                }
            })
        }
    }

    const resetTimer = () => {
        showNavigation();
        setShowBackArrow(true);
        endTimer();
    }

    const timerFinished = () => {
        setMessage("Timer Finished");
        resetTimer();
    }


    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            {showBackArrow && <GoBack onClick={()=>navigate(`/assessments/${assessment.id}`)} />}
            <Header label={title} />

            <Grid container spacing={2}>
                <Grid xs={6} sm={6}> 
                    <Typography>{elephant.name}</Typography>
                    <Typography>{sectiona.observation_range}</Typography>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <EwpButton onClick={toggleTimer}>
                        {(isRunning) ? <Timer onCompletion={timerFinished} /> : <TimerIcon />}
                    </EwpButton>
                </Grid>
                <Grid xs={6} sm={6}> 
                </Grid>
            </Grid>

            <FormProvider onChange={onChange} onSubmit={onSubmit} defaultValues={sectiona} >
                <FormOnChange>
                    <Grid container spacing={2} sx={{pr: (!smAndLarger) ? 3 : 0}}>


                        <Grid xs={12} sm={12}> 
                            <FormMultiSelectChips enableSelectNone enableSelectAll name="other_elephants_nearby" label="Other Elephants Nearby" list={otherElephantsNearby(elephants,elephant)} />
                        </Grid>

                        {showForm && <>
                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="content" label="Content" leftLabel="Not Content" rightLabel="Content" tooltipKey="section_a_content" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="relaxed" label="Relaxed" leftLabel="Not Relaxed" rightLabel="Relaxed" tooltipKey="section_a_relaxed" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="uncomfortable" label="Uncomfortable" leftLabel="Comfortable" rightLabel="Uncomfortable" tooltipKey="section_a_uncomfortable" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="agitated" label="Agitated" leftLabel="Not Agitated" rightLabel="Agitated" tooltipKey="section_a_agitated" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="tense" label="Tense" leftLabel="Not Tense" rightLabel="Tense" tooltipKey="section_a_tense" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="frustrated" label="Frustrated" leftLabel="Not Frustrated" rightLabel="Frustrated" tooltipKey="section_a_frustrated" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="wary" label="Wary" leftLabel="Not Wary" rightLabel="Wary" tooltipKey="section_a_wary" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="playful" label="Playful" leftLabel="Not Playful" rightLabel="Playful" tooltipKey="section_a_playful" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="sociable" label="Sociable" leftLabel="Not Sociable" rightLabel="Sociable" tooltipKey="section_a_sociable" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="lively" label="Lively" leftLabel="Not Lively" rightLabel="Lively" tooltipKey="section_a_lively" />
                        </Grid>

                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <CustomSlider name="lethargic" label="Lethargic" leftLabel="Not Lethargic" rightLabel="Lethargic" tooltipKey="section_a_lethargic" />
                        </Grid>



                        <Grid xs={12}> 
                            <FormButton>Submit</FormButton>
                        </Grid>
                        </>}

                    </Grid>

                </FormOnChange>
            </FormProvider>
        </Container>
    );
}