import * as React from 'react';

import {useLoaderData, useNavigate} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import FormTextField from '../components/form/FormTextField';
import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormButton from '../components/form/FormButton';
import FormDatePicker from '../components/form/FormDatePicker';

import Header from "../components/Header";
import Subheader from "../components/Subheader";

import { getElephant, saveElephant } from '../models/elephants';
import GoBack from '../components/GoBack';

import { useConnectivity } from '../context/ConnectivityProvider';


export async function loader({ params }) {
    console.log("loader params", params);
    if (!params.elephantId) throw new Error("Need to pick a defined elephant first");

    //const elephants = await listElephants();
    const elephant = await getElephant(params.elephantId);
    return {elephant};
}

const ElephantDeathDepartureFormPage = () => {

    const {setSuccess, setError} = useMessage();
    const {facilityId} = useAuth();
    const {isOnline} = useConnectivity();
    const navigate = useNavigate();

    const {elephant} = useLoaderData(); 



    const handleDeparture = async (data) => {
        try {
            console.log("death", data);

            const tempElephant = {...elephant,departed_on: data.departed_on, additional_information_about_departure: data.additional_information_about_departure};

            const saveResult = await saveElephant(tempElephant,facilityId,isOnline);
            console.log("save result", saveResult);

            setSuccess("Saved Departure Information");
        } catch (error) {
            console.error(error);
            setError(error.message);
        }
    };


    const handleDeath = async (data) => {
        try {
            console.log("death", data);

            const tempElephant = {...elephant,died_on: data.died_on};

            const saveResult = await saveElephant(tempElephant,facilityId,isOnline);
            console.log("save result", saveResult);


            setSuccess("Saved Death Of Animal");
        } catch (error) {
            console.error(error);
            setError(error.message);
        }
    };



    return (

        <Container component="main" maxWidth="sm">
            <GoBack onClick={()=>navigate(`/elephants/${elephant.id}`)} />
            <Header label="Death/Departure" />

            <Subheader label={elephant.name} />

            <Subheader label="Departure From Facility" />

            <FormProvider onSubmit={handleDeparture} defaultValues={{departed_on: elephant.departed_on || null, additional_information_about_departure: elephant.additional_information_about_departure || ''}} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} display="flex" justifyContent="center" alignItems="center"> 
                            <FormDatePicker name="departed_on" label="Date of Departure" required/>
                        </Grid>
                        <Grid xs={12} sm={12} display="flex" justifyContent="center" alignItems="center"> 
                            <FormTextField name="additional_information_about_departure" label="Departure Notes"/>
                        </Grid>
                        <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
                            <FormButton>Submit</FormButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormProvider>


            <Subheader label="Death Of Animal" />

            <FormProvider onSubmit={handleDeath} defaultValues={{died_on: elephant.died_on || null}} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} display="flex" justifyContent="center" alignItems="center"> 
                            <FormDatePicker name="died_on" label="Died On" required/>
                        </Grid>
                        <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
                            <FormButton>Submit</FormButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormProvider>

        </Container>
    )
}
export default ElephantDeathDepartureFormPage;