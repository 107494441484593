import * as React from 'react';

import {useForm} from './FormProvider';

import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';

import { Controller } from "react-hook-form"

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



export default function FormDatePicker({name,label,required,rules = {},fullWidth, ...props}) {

    const {control, defaultRules, errors, watch } = useForm();

    const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    rules = {...requiredDefaultRule,...rules};

    fullWidth = (fullWidth === undefined) ? true : fullWidth;

    const watchValue = watch(name);

    return (
        <Controller
            render={({field})=><LocalizationProvider dateAdapter={AdapterDayjs}>

              <FormControl fullWidth={fullWidth} error={(errors[name]) ? true : false}>
                <DatePicker label={label} value={watchValue} {...field} />
                {errors[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
              </FormControl>



            </LocalizationProvider>}
            name={name}
            control={control}
            rules={rules}
        />
    );
};