import * as React from 'react';

import {useParams, useLoaderData, useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import GoBack from '../components/GoBack';

import Subheader from "../components/Subheader";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {date} from "../utils/datetime";
import { listLabel } from '../utils/lists';

import ElephantNameList from '../components/ElephantNameList';

import {categoryList, typeList, statusList} from '../models/categories';
import {getChange} from '../models/changes';
import {listElephants} from '../models/elephants';
import { listUsers } from '../models/users';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    console.log("loader params",params);
    const change = await getChange(params.changeId);
    const elephants = await listElephants();
    const users = await listUsers();

    return {
        change,
        elephants,
        categories: await categoryList(),
        types: await typeList(),
        statuses: await statusList(),
        users
    };
}


export default function ChangeDetailsPage({title = "EWP",...props}) {
    const params = useParams();
    const navigate = useNavigate();
    console.log(params);

    // used to read the loader data that is returned from the function above
    const {change,elephants,categories,types,statuses,users} = useLoaderData(); 

    const elephantLookup = elephants.reduce((acc,cur)=>{
        acc[cur.id] = cur;
        return acc;
    },{});



    const [anchorEl,setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleResolveClick = (id) => {
        console.log('resolve');
        navigate("/changes/" + change.id + "/resolve")
    }

    // if you're going to do a form submission, this is your starting point for the submit behavior
    /*
    const onSubmit = async (data) => {
        console.log(data);
    }
    */

    const impactedElephants = change.elephants.filter((cur)=>{
        return Object.keys(elephantLookup).includes(cur);
    }).map((id)=>{
        return elephantLookup[id];
    });
    console.log('impactedElephants', impactedElephants);

    const timeframe = (change.start && change.end) ? date(change.start) + " - " + date(change.end) : "Started " + date(change.start);

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Card>
                <CardContent>
                    <GoBack onClick={()=>navigate(-1)} sx={{margin:0,"&:hover":{cursor:"pointer"}}}/>
                    <CardHeader 
                        title={listLabel(change.category,categories)}
                        subheader={timeframe}
                        action={
                        <IconButton aria-label="settings" onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        }
                    />

                    <Subheader label="Details" />
                    <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid xs={6} sm={4}> 
                            <Typography variant="body1" color="secondary">Category</Typography>    
                            <Typography variant="body2">{listLabel(change.category,categories)}</Typography>    
                        </Grid>
                        <Grid xs={6} sm={4}> 
                            <Typography variant="body1" color="secondary">Type</Typography>    
                            <Typography variant="body2">{listLabel(change.change_type,types)}</Typography>    
                        </Grid>
                        <Grid xs={6} sm={4}> 
                            <Typography variant="body1" color="secondary">Status</Typography>    
                            <Typography variant="body2">{listLabel(change.status,statuses)}</Typography>    
                        </Grid>

                        <Grid xs={6} sm={4}> 
                            <Typography variant="body1" color="secondary">Started</Typography>    
                            <Typography variant="body2">{date(change.start)}</Typography>
                        </Grid>

                        <Grid xs={6} sm={4}> 
                            <Typography variant="body1" color="secondary">Ended</Typography>    
                            <Typography variant="body2">{(change.end) ? date(change.end) : ""}</Typography>
                        </Grid>

                        <Grid xs={6} sm={4}> 
                            <Typography variant="body1" color="secondary">Is Resolved</Typography>    
                            <Typography variant="body2">{(change.is_resolved) ? "Yes" : "No"}</Typography>
                        </Grid>
                        <Grid xs={6} sm={4}> 
                            <Typography variant="body1" color="secondary">Reported by</Typography>    
                            <Typography variant="body2">{users?.find(user=>user.id===change.user_id).name}</Typography>
                        </Grid>
                    </Grid>

                    <Subheader label="Description" />
                    <Typography variant='body2'>{change.description}</Typography>


                    {change.resolved_information && <>
                        <Subheader label="Resolution Information" />
                        <Typography variant='body2'>{change.resolved_information}</Typography>
                    </>}

                    <Subheader label="Elephants Impacted" />
                    <ElephantNameList showLink elephants={impactedElephants} />

                </CardContent>
            </Card>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleResolveClick} disabled={change.is_resolved}>Resolve</MenuItem>
            </Menu>
        </Container>
    );
}