import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../db';
import { errorMessage } from '../utils/errors';
import { today, syncvalue, dateFromStorage, dateToStorage} from "../utils/datetime";
import { booleanToInt, intToBoolean } from '../utils/sections';
import { syncRecordsets, handleRemoteDeletions } from '../utils/sync';

const template = () => {
    const record = {
        id: null,
        assessment_id: null,
        user_id: null,
        observation_range: null,
        observed_on: null,
        submitted_on: null,
        completed_on: null,
        feeding: 0,
        lying_down_alone: 0,
        lying_down_with_others: 0,
        lying_down_with_others_elephants: [],
        stereotyping: 0,
        stereotyping_description: null,
        self_maintenance: 0,
        self_maintenance_description: null,
        interaction_with_environment: 0,
        interaction_with_environment_description: null,
        social_affiliative: 0,
        social_affiliative_elephants: [],
        other: 0,
        other_description: null,
        out_of_view: 0,
        additional_comments: null,
    };

    return { ...record };
};

const fromSupabase = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateFromStorage(temp['observed_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    temp["feeding"]=intToBoolean(temp["feeding"]);
    temp["lying_down_alone"]=intToBoolean(temp["lying_down_alone"]);
    temp["lying_down_with_others"]=intToBoolean(temp["lying_down_with_others"]);
    temp["stereotyping"]=intToBoolean(temp["stereotyping"]);
    temp["self_maintenance"]=intToBoolean(temp["self_maintenance"]);
    temp["interaction_with_environment"]=intToBoolean(temp["interaction_with_environment"]);
    temp["social_affiliative"]=intToBoolean(temp["social_affiliative"]);
    temp["other"]=intToBoolean(temp["other"]);
    temp["out_of_view"]=intToBoolean(temp["out_of_view"]);
    return temp;
}

const toSupabase = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateToStorage(temp['observed_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    temp["feeding"]=booleanToInt(temp["feeding"])
    temp["lying_down_alone"]=booleanToInt(temp["lying_down_alone"])
    temp["lying_down_with_others"]=booleanToInt(temp["lying_down_with_others"])
    temp["stereotyping"]=booleanToInt(temp["stereotyping"])
    temp["self_maintenance"]=booleanToInt(temp["self_maintenance"])
    temp["interaction_with_environment"]=booleanToInt(temp["interaction_with_environment"])
    temp["social_affiliative"]=booleanToInt(temp["social_affiliative"])
    temp["other"]=booleanToInt(temp["other"])
    temp["out_of_view"]=booleanToInt(temp["out_of_view"])
    return {sectionc: temp};
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateFromStorage(temp['observed_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    temp["feeding"]=intToBoolean(temp["feeding"]);
    temp["lying_down_alone"]=intToBoolean(temp["lying_down_alone"]);
    temp["lying_down_with_others"]=intToBoolean(temp["lying_down_with_others"]);
    temp["stereotyping"]=intToBoolean(temp["stereotyping"]);
    temp["self_maintenance"]=intToBoolean(temp["self_maintenance"]);
    temp["interaction_with_environment"]=intToBoolean(temp["interaction_with_environment"]);
    temp["social_affiliative"]=intToBoolean(temp["social_affiliative"]);
    temp["other"]=intToBoolean(temp["other"]);
    temp["out_of_view"]=intToBoolean(temp["out_of_view"]);
    return temp;
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['observed_on'] = dateToStorage(temp['observed_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    temp["feeding"]=booleanToInt(temp["feeding"])
    temp["lying_down_alone"]=booleanToInt(temp["lying_down_alone"])
    temp["lying_down_with_others"]=booleanToInt(temp["lying_down_with_others"])
    temp["stereotyping"]=booleanToInt(temp["stereotyping"])
    temp["self_maintenance"]=booleanToInt(temp["self_maintenance"])
    temp["interaction_with_environment"]=booleanToInt(temp["interaction_with_environment"])
    temp["social_affiliative"]=booleanToInt(temp["social_affiliative"])
    temp["other"]=booleanToInt(temp["other"])
    temp["out_of_view"]=booleanToInt(temp["out_of_view"])
    
    return temp;
}


const newSectionC = () =>  {
    return template();
};


const listSectionCsByAssessment = async(assessmentId) => {
    try {

        const temp = await db().sectionc.where("assessment_id").equals(assessmentId).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionCs = async() => {
    try {

        const temp = await db().sectionc.toCollection().toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionCsBySync = async(lastSync) => {
    try {

        const temp = await db().sectionc.where('sync').aboveOrEqual(lastSync).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchSectionCs = async() => {
    try {
        const fetchResults = await supabase
            .from('section_c')
            .select('*')
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const fetchSectionCsBySync = async(lastSync) => {
    try {
        const fetchResults = await supabase
            .from('section_c')
            .select('*')
            .gte('sync',lastSync)
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const getSectionC = async(id) => {
    try {

        const temp = await db().sectionc.where('id').equals(id).first();
        console.log(temp);
        return fromDexie(temp);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchSectionC = async(id) => {

    try {
        const fetchResults = await supabase
            .from('section_c')
            .select('*')
            .eq('id', id);
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fromSupabase(fetchResults.data[0]);
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const writeSectionCToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().sectionc.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/*
const writeNewSectionCToSupabase = async(inputRecord, facilityId) => {
    try {
        const {assessment} = toSupabase(inputRecord);
        assessment.facility_id = facilityId;
        const assessmentResult = await supabase.from("assessments").insert(assessment);
        if (assessmentResult.error) throw new Error(`Error ${assessmentResult.error.code}: ${assessmentResult.error.message}`);
        return {assessmentResult, id: assessment.id};


    } catch (error) {
        console.error(error);
        throw error;
    }
}
*/
const writeSectionCToSupabase = async(inputRecord) => {
    try {
        const {sectionc} = toSupabase(inputRecord);

        console.log(sectionc);

        const upsertResult = await supabase.from("section_c").upsert(sectionc);
        if (upsertResult.error) throw new Error(errorMessage(upsertResult.error));
        return {upsertResult, id: sectionc.id};

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const saveSectionC = async(inputRecord,isOnline) => {

    const syncValue = syncvalue();
    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;

    const dexieResults = await writeSectionCToDexie(inputRecord);
    const supabaseResults = (isOnline) ? await writeSectionCToSupabase(inputRecord) : {status: "offline"};
    return {dexieResults, supabaseResults, id: inputRecord.id};
}

const saveSectionCLocal = async(inputRecord) => {

    const syncValue = syncvalue();
    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;

    const dexieResults = await writeSectionCToDexie(inputRecord);
    return {dexieResults, id: inputRecord.id};
}

const completeSectionC = async(assessmentId,isOnline) => {
    try {

        const completedOn = today();
        const sectionCs = await listSectionCsByAssessment(assessmentId);

        const results = await Promise.all(sectionCs.map((cur)=>{
            cur.completed_on = completedOn;
            return saveSectionC(cur,isOnline);
        }));

        return results;

    } catch (error) {
        throw error;
    }
}

const sync = async({lastSync = null}) => {

    const deletedRecords = await handleRemoteDeletions({tableName: "section_a", listFunc: listSectionCs, supabase, dbTable: db().sectionc});
    console.log("deletedRecords", deletedRecords);

    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({
        remoteData,
        localData, 
        remoteWriteFunc: writeSectionCToSupabase, 
        localWriteFunc: writeSectionCToDexie, 
    });
    return results;
}

const syncFullRecordSets = async () => {
    const remoteData = await fetchSectionCs() || [];
    const localData = await listSectionCs() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchSectionCsBySync(lastSync) || [];
    const localData = await listSectionCsBySync(lastSync) || [];

    return {remoteData, localData};
};


const truncateDexie = async() => {
    return await db().sectionc.clear();
}


export {
    newSectionC,
    listSectionCs,
    listSectionCsByAssessment,
    getSectionC,
    fetchSectionCs,
    fetchSectionC,
    saveSectionC,
    saveSectionCLocal,
    completeSectionC,
    truncateDexie,
    sync
};
