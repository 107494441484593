import ErrorPage from './ErrorPage';
import IndexPage from './IndexPage';
import IndexConfigurePage from './IndexConfigurePage';
import GenericPage from './GenericPage';
import TestPage from './TestPage';
import TestFormPage from './TestFormPage';
import TestForm2Page from './TestForm2Page';
import TestDataGrid from './TestDataGrid';
import SignInPage from './SignInPage';
import InstitutionDetailsFormPage from './InstitutionDetailsFormPage';
import SyncPage from './SyncPage';
import ElephantListPage from './ElephantListPage';
import ElephantDashboardPage from './ElephantDashboardPage';
import ElephantFormPage from './ElephantFormPage';
import ChangesPage from './ChangesPage';
import ChangeDetailsPage from './ChangeDetailsPage';
import ChangeResolvePage from './ChangeResolvePage';
import DiagnosticsPage from './DiagnosticsPage';
import ElephantRelationshipsForm from './ElephantRelationshipsForm';
import ElephantDeathDepartureFormPage from './ElephantDeathDepartureFormPage.js';
import UsersListPage from './UsersListPage';
import UserFormPage from './UserFormPage';
import UserDashboardPage from './UserDashboardPage';
import UserCreatePage from './UserCreatePage';
import ProfileChangePasswordPage from './ProfileChangePasswordPage.js';
import ProfilePage from './ProfilePage';
import AssessmentsListPage from './AssessmentsListPage.js';
import AssessmentsFormPage from './AssessmentsFormPage.js';
import AssessmentDashboardPage from './AssessmentDashboardPage.js';
import AssessmentSectionAFormPage from './AssessmentSectionAFormPage';
import AssessmentSectionASelectPage from './AssessmentSectionASelectPage';
import AssessmentSectionBFormPage from './AssessmentSectionBFormPage';
import AssessmentSectionBSelectPage from './AssessmentSectionBSelectPage';
import AssessmentSubmitPage from './AssessmentSubmitPage';
import AssessmentSectionCSelectPage from './AssessmentSectionCSelectPage';
import AssessmentSectionCFormPage from './AssessmentSectionCFormPage';
import AssessmentSectionCIncidentsOfAggressionListPage from './AssessmentSectionCIncidentsOfAggressionListPage';
import AssessmentSectionCIncidentsOfAggressionFormPage from './AssessmentSectionCIncidentsOfAggressionFormPage';
import AssessmentFinalEvaluationFormPage from './AssessmentFinalEvaluationFormPage';
import OtpPage from './OtpPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import MoreInfoPage from './MoreInfoPage';


const pages = {
    IndexPage, 
    IndexConfigurePage, 
    ErrorPage,
    GenericPage,
    SignInPage, 
    OtpPage,
    ForgotPasswordPage,
    TestPage, 
    TestFormPage, 
    TestForm2Page, 
    TestDataGrid,
    InstitutionDetailsFormPage, 
    SyncPage,
    ElephantListPage,
    ElephantDashboardPage,
    ElephantFormPage,
    ElephantDeathDepartureFormPage,
    ChangesPage,
    ChangeDetailsPage,
    ChangeResolvePage,
    DiagnosticsPage,
    ElephantRelationshipsForm,
    UsersListPage,
    UserFormPage,
    UserDashboardPage,
    UserCreatePage,
    ProfileChangePasswordPage,
    ProfilePage,
    AssessmentsListPage,
    AssessmentsFormPage,
    AssessmentDashboardPage,
    AssessmentSectionBFormPage,
    AssessmentSectionBSelectPage,
    AssessmentSectionAFormPage,
    AssessmentSectionASelectPage,
    AssessmentSubmitPage,
    AssessmentSectionCSelectPage,
    AssessmentSectionCFormPage,
    AssessmentSectionCIncidentsOfAggressionListPage,
    AssessmentSectionCIncidentsOfAggressionFormPage,
    AssessmentFinalEvaluationFormPage,
    MoreInfoPage,
};

export default pages;